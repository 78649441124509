<template>
  <div>
    <div class="container__search">
      <el-form class="logs-table__form" inline @submit.native.prevent="onSubmit">
        <el-input
          v-model="form.search"
          :disabled="loading"
          placeholder="Поиск..."
          clearable
          @clear="(params.search = ''), onSubmit()"
        />
        <el-button tabindex="1" :loading="loading" type="primary" icon="el-icon-search">
          Найти
        </el-button>
      </el-form>
    </div>
    <el-table v-loading="loading" class="list" :data="logs.list">
      <el-table-column prop="created_at" label="Дата" />
      <el-table-column prop="action" label="Событие" />
      <el-table-column prop="login" label="Логин" />
    </el-table>
    <el-pagination
      background
      :total="logs.total"
      :page-count="pageCount"
      hide-on-single-page
      layout="prev, pager, next"
      @current-change="changePage"
    />
  </div>
</template>
<script>
import { adLogsInitial } from "./logsMixin";

export default {
  name: "AdTableLogs",
  props: {
    logs: {
      type: Object,
      default: () => Object.assign({}, adLogsInitial),
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      params: {
        search: "",
        count: 10,
        page: 1,
      },
      form: {
        search: "",
      },
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.logs.total / 10);
    },
  },
  methods: {
    onSubmit() {
      Object.assign(this.params, this.form, {
        page: 1,
      });
      this.onFetch();
    },
    changePage(page) {
      this.params.page = page;
      this.onFetch();
    },
    onFetch() {
      this.$emit("fetch", this.params);
    },
  },
};
</script>
<style lang="scss">
.list,
.list__pagination {
  margin: 20px 0;
}
.list__pagination {
  text-align: center;
}
.container__action {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.container__search {
  display: flex;
  flex-direction: row;
}

.logs-table {
  &__form {
    max-width: unset;
    width: 100%;
    display: flex;
    & > * {
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__actions {
    display: flex;
  }
}
</style>
