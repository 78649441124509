export const adLogsInitial = {
  total: 0,
  list: [],
};

export default {
  data() {
    return {
      logs: Object.assign({}, adLogsInitial),
      logsLoading: false,
    };
  },
  created() {
    this.onFetchData();
  },
  methods: {
    onFetchData(params) {
      this.logsLoading = true;
      this.fetchLogs(params)
        .then((resp) => {
          this.logs = {
            list: resp.data,
            total: resp.total,
          };
        })
        .finally(() => {
          this.logsLoading = false;
        });
    },
  },
};
