<template>
  <el-container>
    <main class="page__container">
      <div class="container">
        <el-tabs>
          <el-tab-pane label="Список сотрудников">
            <!-- <search :query.sync="query" @search="search" /> -->
            <el-table id="listUsers" class="list list_users" :data="items">
              <el-table-column prop="full_name" label="Полное имя" />
              <el-table-column prop="login" label="Логин" />
              <el-table-column prop="role" label="Роль" />
              <el-table-column prop="position" label="Должность" />
            </el-table>
            <el-pagination
              class="list__pagination"
              layout="prev, pager, next"
              :page-size="count"
              :total="total"
              :current-page="page"
              @current-change="changePage"
            />
          </el-tab-pane>
          <el-tab-pane label="Логи синхронизации">
            <ad-table-logs :logs="logs" :loading="logsLoading" @fetch="onFetchData" />
          </el-tab-pane>
        </el-tabs>
      </div>
    </main>
  </el-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { ApiService } from "@/api";
import routeList from "@/core/mixins/routeList";
import logsMixin from "../../core/components/AdTableLogs/logsMixin";
import AdTableLogs from "../../core/components/AdTableLogs/index";

export default {
  name: "Users",
  components: { AdTableLogs },
  mixins: [routeList, logsMixin],
  data() {
    return {
      query: "",
    };
  },
  computed: {
    ...mapState("users", {
      items: (state) => state.data,
      total: (state) => state.total,
      count: (state) => state.count,
    }),
    ...mapGetters("users", ["getQuery"]),
  },
  methods: {
    ...mapActions("users", {
      getList: "GET_LIST_USERS",
    }),
    fetchLogs(params) {
      return ApiService.users.getLogs(params);
    },
  },
};
</script>
